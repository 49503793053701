.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  text-align: center;
}

h3 {
  text-decoration: underline;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 6em;
  margin-bottom: 1em;
  margin-top: 1em
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.HelpText {
  font-size: .72em;
  font-style: italic;
  color: #BEBEBE;
  margin-left: 30px;
  max-width: 400px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 5px;
  max-width: 90%;
}

.input-group label,
.input-group .result-label {
  margin-right: 10px;
  width: 275px;
  font-size: 1em;
  border-radius: 5px;
}

input[type="number"],
.input-group .result-value {
  -moz-appearance: textfield;
  flex-grow: 1;
  vertical-align: bottom;
  margin-left: 2px;
  font-size: 1em;
  width: 150px;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 5px;
}

.footer {
  font-size: .69em;
  font-style: italic;
  text-align: left;
  margin-top: 50px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.footer a:link {
  color: white;
}

.footer a:visited {
  color: white;
}

.footer a:hover {
  color: hotpink;
}

.copyrightcenter {
  text-align: center;
}

@media screen and (max-width: 600px) {

  html,
  body {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    font-size: 1.75em;
  }

  h3 {
    font-size: 1.50em;
  }

  .HelpText {
    font-size: 1em
  }

  .input-group {
    flex-direction: column;
  }

  input[type="number"],
  .input-group .result-value {
    margin-left: 0;
    margin-top: 10px;
    width: 70%;
    padding: 2px;
    font-size: 1.35em;
  }

  .input-group label,
  .input-group .result-label {
    width: auto;
    font-size: 1.35em;
  }

  button {
    padding: 15px 20px;
    font-size: 16px;
  }
}